// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  //api:"https://api.microwas.com/swim/Api"
  //api:"http://localhost/duo/awl-swim/Api"
  //api:"https://testswim2.microwas.com/swim/Api" 
  //api:"https://swim.awlworldwide.com/Api"
  //api:"https://swim2.microwas.com/swim/Api" 
  // api:"https://swim.awlworldwide.com/testSwim/Api"   // Test API
  api:"https://hhtwindows.awlinternational.com/Api"

  //api:"https://testswim2.awlworldwide.com/Api"
  //api:"https://testswim2.awlworldwide.com/Api"
  //api:"http://192.168.146.118/Api"

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
